'use strict';

angular.module('enervexSalesappApp').controller('FanPhaseCtrl', function($scope, FanPhase, $stateParams, Flash, $state, _, Fan, Voltage) {
	$scope.errors = {};
	if ($stateParams.id == "new") {
		$scope.fanPhase = {
			fans:[],
			voltages:[]
		}
		fetchLookups()
	} else {
		FanPhase.get({
			id: $stateParams.id
		}).$promise.then(function(fanPhase) {
			$scope.fanPhase = fanPhase;
			fetchLookups()
		})
	}
	$scope.ctx = {
		formChanged: false
	}
	function fetchLookups() {
		Fan.query({
			limit:3000
		}).$promise.then(function(_fans){
			var fans = _.sortBy(_.uniq(_.pluck(_fans, "code")), function(code){
				return code
			})
			$scope.fans = _.map(fans, function(original){
				var fan = {
					code: original,
					selected: false
				}
				var existing = _.find($scope.fanPhase.fans, function(f){
					return f == fan.code
				})
				if (existing){
					fan.selected = true
				}
				return fan
			});
		})
		Voltage.query().$promise.then(function(voltages){
			$scope.voltages = _.map(voltages, function(voltage){
				var existing = _.find($scope.fanPhase.voltages, function(v){
					return v == voltage.code
				})
				if (existing){
					voltage.selected = true
				}
				return voltage
			})
		})

	}
	$scope.fanTypes = [
		"supply", 
		"exhaust", 
	];

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.fanPhase);
			payload = _.omit(payload, "__v");
			payload.fans = _.pluck(_.where($scope.fans, {selected:true}), "code")
			payload.voltages = _.pluck(_.where($scope.voltages, {selected:true}), "code")
			if (!$scope.fanPhase._id) {
				FanPhase.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					// $scope.fanPhase = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created fan phase.', 0, {}, true);
					$state.go("fan-phase", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				FanPhase.update({
					id: $scope.fanPhase._id
				}, payload).$promise.then(function(fanPhase) {
					
					$scope.success = true;
					// $scope.fanPhase = fanPhase;
					Flash.create('success', '<strong>Success!</strong> Successfully updated fan phase.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});